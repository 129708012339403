<template>
  <div class="sender">
    <div class="sender-header">
      <span class="sender-title">寄件人</span>
      <span class="address-import" v-if="!confirmStatus" @click="useSender">从地址簿导入</span>
      <span class="address-edit" v-else  @click="openEdit">编辑</span>
    </div>

    <!-- 寄件人表单 -->
    <div class="sender-form" v-if="!confirmStatus">
      <el-form label-position="top" label-width="80px" :model="sender" :rules="rules" class="form" ref="senderForm">
        <el-form-item label="名称" class="sender-form-item" prop="name">
          <el-input v-model="sender.name" placeholder="姓名"></el-input>
        </el-form-item>
        <el-form-item label="电话号码" class="sender-form-item" prop="phone">
          <el-input v-model="sender.phone" placeholder="电话号码"></el-input>
        </el-form-item>
        <el-form-item label="邮编" class="sender-form-item zipCode" prop="zipCode">
          <el-input v-model="sender.zipCode" placeholder="邮编">
            <el-button slot="append"  @click="queryClicked">查询地址</el-button>
          </el-input>
          <el-cascader-panel :options="zipCodeOptions" v-if="zipCodeOptions.length != 0" @change="getNodes"></el-cascader-panel>
        </el-form-item>
        <!-- <div class="query-btn posi" @click="queryClicked">查询地址</div> -->
        <el-form-item label="城市" class="address" required>
          <el-form-item class="address2" prop="addressOne">
            <el-input v-model="sender.addressOne" class="sender-form-input"  style="margin-right:0;"  placeholder="城市"></el-input>
          </el-form-item>
          <el-form-item class="address2" prop="addressTwo">
            <el-input v-model="sender.addressTwo" class="sender-form-input" style="margin-right:0;" placeholder="地址1"></el-input>
          </el-form-item>
          <el-input v-model="sender.addressThree" class="sender-form-input" placeholder="地址2"></el-input>
          <el-input v-model="sender.addressFour" class="sender-form-input" placeholder="地址3"></el-input>
        </el-form-item>
      </el-form>
      <div v-if="!showNew" class="checkbox-box">
        <el-checkbox v-model="sender.defaultFlag">设置为默认寄件人（下次下单自动填写该地址）</el-checkbox>
      </div>
      <div v-else class="checkbox-box">
        <el-checkbox v-model="setNewSender" v-if="!sender.hideSetNew">另存为新的寄件人（保存至地址薄）</el-checkbox>
      </div>
      <div class="confirm" @click="confirm('senderForm')" v-if="show">确定，继续填写收件人</div>
    </div>
    <!-- 确定寄件人后展示数据 -->
    <div v-else style="display: inline-flex;">
      <span class="sender-now">
        姓名：{{sender.name}}
      </span>
      <span class="sender-now">
        电话号码：{{sender.phone}}
      </span>
      <span class="sender-now">
        邮编：{{sender.zipCode}}
      </span>
      <span class="sender-now sender-address">
        地址：{{sender.addressOne + ' ' + sender.addressTwo + ' ' + sender.addressThree + ' ' + sender.addressFour}}
      </span>
    </div>

    <!-- 地址簿 -->
    <div v-if="addressTableVisable" class="address-sender-table-div">
      <el-dialog title="" :visible.sync="addressTableVisable" :modal-append-to-body="false" width="1000px" :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false">
        <!-- 标题 -->
        <div class="title-div">
          <div class="title-text">地址簿</div>
          <div class="title-input" @change="handleSeachChanged">
            <el-input placeholder="搜索姓名和电话" suffix-icon="el-icon-search" v-model="addressSearchContent"></el-input>
          </div>
        </div>
        <!-- 关闭按钮 -->
        <div class="close-btn">
          <i class="el-icon-close address-close posi" @click="addressTableVisable = false"></i>
        </div>
        <!-- 寄件人 -->
        <div class="table-div address-sender-table-div">
          <el-table
          :data="addressSenderData"
          style="width: 100%"
          border
          empty-text="暂无搜索结果"
          @row-click="handleRowClicked"
          v-loading = "addressLoading"
          >
            <el-table-column prop="name" label="姓名" width="124" class="posi" :resizable="false">
              <template slot-scope="scope">
                <span v-if="!scope.row.isEidt">{{scope.row.name}}</span>
                <div v-else class="table-input-div">
                  <el-input v-model="scope.row.name"></el-input>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="phone" label="电话" width="124" :resizable="false">
              <template slot-scope="scope">
                <span v-if="!scope.row.isEidt">{{scope.row.phone}}</span>
                <div v-else class="table-input-div">
                  <el-input v-model="scope.row.phone"></el-input>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="addressOne" label="城市" width="99" :resizable="false">
              <template slot-scope="scope">
                <span v-if="!scope.row.isEidt">{{scope.row.addressOne}}</span>
                <div v-else class="table-input-div">
                  <el-input v-model="scope.row.addressOne"></el-input>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="addressTwo" label="地址" width="454" :resizable="false">
              <template slot-scope="scope">
                <span v-if="!scope.row.isEidt">{{scope.row.addressTwo}} {{scope.row.addressThree}} {{scope.row.addressFour}}</span>
                <div v-else class="table-input-div">
                  <el-input v-model="scope.row.addressTwo"></el-input>
                </div>
              </template>
            </el-table-column>
            <!-- <el-table-column prop="addressThree" label="地址2" width="150" :resizable="false">
              <template slot-scope="scope">
                <span v-if="!scope.row.isEidt">{{scope.row.addressThree}}</span>
                <div v-else class="table-input-div">
                  <el-input v-model="scope.row.addressThree"></el-input>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="addressFour" label="地址3" width="151" :resizable="false">
              <template slot-scope="scope">
                <span v-if="!scope.row.isEidt">{{scope.row.addressFour}}</span>
                <div v-else class="table-input-div">
                  <el-input v-model="scope.row.addressFour"></el-input>
                </div>
              </template>
            </el-table-column> -->
            <el-table-column prop="zipCode" label="邮编" width="90" :resizable="false">
              <template slot-scope="scope">
                <span v-if="!scope.row.isEidt">{{scope.row.zipCode}}</span>
                <div v-else class="table-input-div">
                  <el-input v-model="scope.row.zipCode"></el-input>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="操作" :resizable="false">
              <template slot-scope="scope">
                <div class="edit-div">
                  <!-- <div class="edit-text posi" v-if="!scope.row.isEidt" @click.stop="eidtClicked(scope.$index, scope.row)">编辑</div>
                  <div class="edit-text posi" v-else @click.stop="saveClicked(scope.$index, scope.row)">保存</div>
                  <div class="space-text posi">｜</div> -->
                  <div class="edit-text posi" @click.stop="deletedClicked(scope.$index, scope.row)">删除</div>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import {uploadIdentityImage,listAllSenderAddress,delSenderAddress,getInfoByPostcode} from "@/api/address/sender/sender"
export default {
  name:'sender',
  props:{
    sender:{
      type: Object,
      default:() => {}
    },
    show:{
      type: Boolean,
      default: true
    },
    showNew:{
      type: Boolean,
      default: true
    }
  },
  data(){
    // 此处自定义校验英国邮编js逻辑
    var zipCodeReg = /^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z]))))\s?[0-9][A-Za-z]{2})$/
    var validatePhone = (rule, value, callback) => {
      if (!zipCodeReg.test(value)) {
        callback(new Error('请输入英国邮编'))
      } else {
        callback()
      }
    }
    // 校验只能输入英文空格数字 验证地址
    var addressReg = /^[a-z0-9.\s]+$/i
    var validateAddress = (rule, value, callback) => {
      if (!addressReg.test(value)) {
        callback(new Error('请输入英文'))
      } else {
        callback()
      }
    }
    // 校验只能输入英文空格 验证名字
    var nameReg = /^[a-z\s]+$/i
    var validateName = (rule, value, callback) => {
      if (!nameReg.test(value)) {
        callback(new Error('请输入英文'))
      } else {
        callback()
      }
    }
    return{
      // 寄件人表单
      sender_from:{
        name:'',
        phone:'', 
        zip_code:'',
        city:'',
        address:'',
        address2:'',
        address3:'',
      },
      // 寄件人邮编查询结果数组
      zipCodeOptions:[],
      // 设置默认寄件人
      setDefault:true, 
      // 另存为新的寄件人
      setNewSender:true,
      confirmStatus:false,
      rules:{
        name: [
          { required: true, validator:validateName, trigger: 'change' },
        ],
        phone: [
          { required: true, message: '请输入正确的电话号码', trigger: 'change' },
        ],
        zipCode: [
          { required: true, validator:validatePhone, trigger: 'change' },
        ],
        addressOne:[
          { required: true, validator:validateAddress, trigger: 'change' },
        ],
        addressTwo:[
          { required: true, validator:validateAddress, trigger: 'change' },
        ]
      },
      // 地址簿搜索条件
      addressSearchContent: "",
      // 地址簿是否显示
      addressTableVisable: false,
      // 地址簿-寄件人数组信息
      addressSenderData: [
        // {
        //   id: "",
        //   name: "JIANG",
        //   phone: "07588988866",
        //   postCode: "CV1 2UD",
        //   addressOne: "COVENTRY",
        //   addressTwo: "34 RODYARDway",
        //   addressThree: "CV1 2UD",
        //   addressFour: "UK",
        //   // 编辑操作还是保存操作
        //   isEidt: false,
        // },
      ],
      addressLoading: false,
      //邮编查询结果
      postCodeAdress: [
        {
          addressOne:"COVENTRY",
          addressTwo:"34 RODYARDway",
          addressThree:"CV1 2UD",
          addressFour: "UK"
        }
      ],
    }
  },
  methods:{
    // 选择邮编所在地址
    getNodes(row){
      console.log("row", row)
      this.sender.addressOne = row[0].town
      this.sender.addressTwo = row[0].line_1
      this.sender.addressThree = row[0].line_2
      this.sender.addressFour =  row[0].line_3
      this.zipCodeOptions = []
    },
    //邮编查询
    getInfoByPostcode(){
      let updata = {
        postcode: this.sender.zipCode
      }
      getInfoByPostcode(updata).then(res =>{
        if(res.data.code === 0){
          // this.zipCodeOptions
          let data = res.data.data.info;
          // 所有的路线数组
          let delivery_points = data.delivery_points
          for(let i = 0; i < delivery_points.length; i++){
            let item = delivery_points[i]
            let obj = {
              label:`${item.line_1 ? item.line_1 + ',' : ''}${item.line_2 ? item.line_2 + ',' : ''}${item.line_3 ? item.line_3 + ',' : ''}${data.town}`,
              value:{
                town:data.town,
                line_1:item.line_1,
                line_2:item.line_2,
                line_3:item.line_3,
              },
            }
            this.zipCodeOptions.push(obj);
          }
        }
        // if(res.data.code === 0){
        //   this.sender.addressOne = res.data.data.info.country;
        //   this.sender.addressTwo = res.data.data.info.lsoa;
        //   this.sender.addressThree = res.data.data.info.primaryCareTrust;
        //   this.sender.addressFour = res.data.data.info.region;
        // }
         console.log(res, '查询结果');
      })
    },
    // 编辑寄件人
    openEdit(){
      this.confirmStatus = false
      this.$emit('sender', this.confirmStatus)
    },
    // 确认寄件人信息
    confirm(refName){
      this.$refs[refName].validate((valid) => {
        if(valid){
          this.confirmStatus = true
          this.setAddress()
          this.$emit('sender', this.confirmStatus)
        }else{
          console.log('error submit!!');
          return false;
        }
      })
    },
    //点击查询
    queryClicked(){
      console.log("查询按钮被点击");
      if(this.sender.zipCode)
      {
        this.getInfoByPostcode();
      }
      
    },
    // 新增地址簿
    setAddress(){
      if(this.setNewSender){
        let data = {...this.sender};
        data.defaultFlag = data.defaultFlag? 0:1;
        uploadIdentityImage(data).then(res=>{
          console.log(res);
        })
      }
    },
    // 获取地址簿联系人
    getAddressData(search){
      this.addressLoading = true
      listAllSenderAddress({'nameOrPhone' : search}).then(res=>{
        this.addressLoading = false;
        if(res.data && res.data.code == 0 && res.data.data.list){
          this.addressSenderData = res.data.data.list;
          this.addressSenderData.forEach(item=>{
            item.isEidt = false;
          })
        }
      }).catch(err=>{
        console.error(err);
        this.addressLoading = false;
      });
    },
    //从地址簿导入
    useSender(){
      this.addressTableVisable = true;
      // 获取地址簿
      this.getAddressData();
    },
    // 搜索条件发生变化
    handleSeachChanged(){
      console.log("寄件人搜索条件发生变化",this.addressSearchContent);
      this.getAddressData(this.addressSearchContent);
    },
    // 地址簿行被点击选择
    handleRowClicked(arg1){
      console.log("寄件人选择了行",arg1)
      this.$emit("updateSender",arg1)
      this.addressTableVisable = false;
    },
    // 地址簿编辑被点击
    eidtClicked(arg1,arg2){
      console.log("地址簿-寄件人编辑被点击",arg1,arg2);
      arg2.isEidt = true;
    },
    // 地址簿保存被点击
    saveClicked(arg1,arg2){
      console.log("地址簿-寄件人保存被点击",arg1,arg2);
      arg2.isEidt = false;
    },
    // 地址簿删除被点击
    deletedClicked(arg1,arg2){
      console.log("地址簿-寄件人删除被点击",arg1,arg2);
      delSenderAddress({'addressId':arg2.id}).then(res=>{
        console.log(res)
        if(res.data && res.data.code == 0){
           this.addressSenderData.splice(arg1,1);
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.sender{
  background-color:#fff;
  padding:30px;
  border-radius: 8px;
  &::v-deep .el-input__inner{
    border-radius: 8px;
  }
  .zipCode{
    &::v-deep .el-input__inner{
    border-radius: 8px;
    border-bottom-right-radius: 0;;
    border-top-right-radius: 0;
   }
   &::v-deep .el-input-group__append{
     border-top-right-radius: 8px;
     border-bottom-right-radius: 8px;
     background: #6D5EFE;
     color: #fff;
   }
   position: relative;
  //  下拉框
    &::v-deep .el-cascader-panel{
      position:absolute;
      z-index: 10;
      width: 100%;
      background:#fff;
      .el-cascader-menu{
        width: 100%;
      }
    }
  }
  .sender-header{
    margin-bottom: 40px;
    // 寄件人
    .sender-title{
      font-size: 24px;
      font-weight: bold;
      color: #1E2B4F;
      margin-right: 24px;
    }
    // 从地址簿导入按钮
    .address-import{
      display:inline-block;
      width: 117px;
      height: 36px;
      background: #FFFFFF;
      border-radius: 22px;
      border: 1px solid @bg-color;
      color:@bg-color;
      text-align: center;
      line-height: 36px;
      font-size: 14px;
      cursor: pointer;
    }
    // 编辑
    .address-edit{
      width: 60px;
      height: 36px;
      display: inline-block;
      border:1px solid @bg-color;
      border-radius: 22px;
      color: @bg-color;
      font-size: 14px;
      text-align: center;
      line-height:36px;
      cursor: pointer;
      float: right;
    }
  }
  // 寄件人表单
  .sender-form{
    .form{
      margin-bottom: 26px;
    }
    &::v-deep .el-form{
      display:flex;
      flex-wrap: wrap;
    }
    .sender-form-item{
      width: 220px;
      margin-right: 20px;
    }
    .sender-form-input{
      width: 220px;
      margin-right: 20px;
      &:last-child {
        margin-right: 0;
      }
    }
    .address{
      &::v-deep .el-form-item__content{
        display:flex;
      }
    }
    .address2{
      margin-right: 20px;
    }
  }
  // 选中确定的寄件人
  .sender-now{
    font-size: 16px;
    color: #1E2B4F;
    margin-right: 50px;
    min-height: 20px;
  }
  .sender-address{
    width: 300px;
    margin-right: 0;
    display:inline-block;
    overflow:hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    -o-text-overflow:ellipsis;
  }

  // 地址簿
  .address-sender-table-div{
    position: relative;
    ::v-deep .el-dialog__wrapper{
      .el-dialog{
        border-radius: 8px;
        overflow: hidden;
        .el-dialog__header{
          padding: 0px;
        }
        .el-dialog__body{
          padding: 0px 0px;
        }
      }
    }
    .title-div{
      margin: 25px 0px;
      display: flex;
      align-items: center;
      .title-text{
        height: 33px;
        font-size: 24px;
        font-weight: 600;
        color: #1E2B4F;
        line-height: 33px;
        margin-left: 30px;
        margin-right: 30px;
      }
      .title-input{
        ::v-deep .el-input{
          width: 230px;
          .el-input__inner{
            background: #FFFFFF;
            border-radius: 10px;
            border: 1px solid #D2D5DC;
          }
          .el-input__suffix{
            color: #6D5EFE;
            font-size: 18px;
          }
        }
      }
    }
    .close-btn{
      position: absolute;
      top: 20px;
      right: 20px;
      .address-close{
        font-size: 20px;
        color: #6D5EFE;
        line-height: 20px;
      }
    }
    .edit-div{
      display: flex;
      flex-direction: row;
      justify-content: center;
      .edit-text{
        height: 20px;
        font-size: 14px;
        font-weight: 400;
        color: #6D5EFE;
        line-height: 20px;
      }
      .space-text{
        height: 20px;
        font-size: 14px;
        font-weight: 400;
        color: #B1B6C3;
        line-height: 20px;
      }
    }
    .table-input-div{
      ::v-deep .el-input .el-input__inner{
        height: 36px;
        border-radius: 4px;
        padding: 0px 7px;
      } 
    }
  }

  .checkbox-box{
    margin-bottom: 20px;
    &::v-deep .el-checkbox__input.is-checked+.el-checkbox__label,
    &::v-deep .el-checkbox{
      color: #1E2B4F;
    }
    &::v-deep .el-checkbox__input.is-checked .el-checkbox__inner{
      background-color:@bg-color;
      border-color:@bg-color;
    }
    &::v-deep .el-checkbox__inner:hover,
    &::v-deep .el-checkbox__input.is-focus .el-checkbox__inner
    {
      border-color: @bg-color;
    }
  }

  .confirm{
    height: 44px;
    width: 200px;
    background-color:@bg-color;
    font-size: 16px;
    color: #fff;
    line-height: 44px;
    text-align: center;
    border-radius: 8px;
    margin:0 auto;
    margin-top: 10px;
    cursor: pointer;
  }
  .ver-is-disabled{
    background-color:#C2BBC8 !important;
    border-color:#C2BBC8 !important;
  }
  .query-btn{
    margin-top: 50px;
      width: 94px;
      height: 40px;
      background: #6D5EFE;
      border-radius: 10px;
      font-size: 14px;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 40px;
      text-align: center;
      margin-left: 20px;
  }
}
</style>